var HelpChat = function() {
  this.question = ""


  this.run = function() {};
  this.addMessage = function(msg, userID) {
    var d = new Date();
    return {
      userID: userID,
      msg: msg,
      date:
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes(),
      view: [1, 4]
    };
  };
  this.hideInput = function() {
    document.getElementsByClassName("insert_area")[0].hidden = true;
  };
  this.hideThumbButton = function() {
    document.getElementsByClassName("thumbButton")[0].remove();
  };
  this.showInput = function() {
    document.getElementsByClassName("insert_area")[0].hidden = false;
    this.hideThumbButton();
  };
  this.addQuestion = function(msg) {
    return msg.msg;
  };
  this.getAnswer = function(msg) {
    var answer = "";
    this.buttons.forEach(
      function(btn, key) {
        if (btn === msg) {
          switch (key) {
            case 0:
              answer = this.setAnswerUniversal();
              setTimeout(
                function() {
                  this.checkClick(
                    function() {
                      this.getOpenHelpArea();
                    }.bind(this)
                  );
                }.bind(this),
                500
              );
              break;

            case 1:
              answer = this.setAnswerContact();
              break;
            case 2:
              this.showInput();
              answer = this.setAnswerIndividual();
              break;
          }
        }
      }.bind(this)
    );
    return answer;
  };
  this.setAnswerIndividual = function() {
    return this.addMessage(this.messages.writeComment, 1);
  };
  this.setAnswerUniversal = function() {
    return this.addMessage(
      this.messages.helpArea + "<br><br>" + this.messages.openHelpArea,
      1
    );
  };
  this.setAnswerContact = function() {
    return this.addMessage(this.messages.contact, 1);
  };
  this.checkClick = function(callback) {
    var anchors = document.getElementsByClassName("flix-btn-answer");
    for (var i = 0; i < anchors.length; i++) {
      var anchor = anchors[i];
      anchor.onclick = function() {
        callback(this.innerHTML);
        return false;
      };
    }
  };
};
export { HelpChat };
