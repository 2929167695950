<template>
  <div>
    <div ref="chat"></div>

  </div>
</template>
<script>
import { externalChat } from "@/components/chatUpdate.js";
import { HelpChat } from "@/components/default/helpChat.js";
import { de_DE } from "@/components/default/languages/de_DE.js";
import { en_EN } from "@/components/default/languages/en_EN.js";
export default {
  components: {},
  props: {},
  data() {
    this.getHC();
    return {
      messages: [hC.addMessage(hC.messages.welcome, 1)],
      chatData: {},
      demo: 0,
      question: "",
      email: ""
    };
  },
  methods: {
    getHC () {
      var hC = new HelpChat();
      var lang = {
        'de': new de_DE(),
        'en': new en_EN()
      }
      window.hC = Object.assign(hC, lang[this.$route.params.lang])
    },
    openHelpArea() {
      this.$router.push({ name: "dashboard" });
    },
    loadChat() {
      this.chatData = {
        language: this.$route.params.lang,
        messages: this.messages,
        legend: false, // false | all | active | me | array [1,2]
        activeUser: 4,
        users: {
          1: {
            name: "Flix von Terminflix",
            background: "#8dc044",
            color: "#000",
            avatar: "https://bookingflix.com/assets/img/chat/flix_avatar.jpeg"
          },
          4: {
            name: "Ich",
            background: '#1A237E',
            color: "#fff",
            avatar: "https://bookingflix.com/assets/img/chat/customer_avatar.jpg"
          }
        },
        postMsg: function(email, msg) {
          this.$flix_post({
            url: 'support_chats/create',
            data: {
              email: email,
              msg: msg
            },
            callback: function (ret) {
              window.console.log(ret)
            }
          })
        }.bind(this),
        setQuestion: function(msg) {
          this.question = hC.addQuestion(msg);
          hC.hideInput();
          setTimeout(function () {
            this.messages.push(hC.addMessage(hC.messages.emailAdress, 1));
            hC.showInput();
          }.bind(this), 2000)
        },
        setEmail: function(msg) {
          this.email = hC.addQuestion(msg).trim();
          hC.hideInput();
          if (!this.validEmail(this.email)){
            this.email = "";
            this.messages.push(hC.addMessage(hC.messages.emailAdress, 1));
            hC.showInput();
            return false;
          }
          this.postMsg(this.email, this.question);
          setTimeout(function () {
            this.messages.push(hC.addMessage(hC.messages.saveEmail, 1));
          }.bind(this), 2000)
        },
        validEmail: function (email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
        },
        onSubmit: function(msg) {
          if (!this.question) {
            this.setQuestion(msg);
            return false;
          }
          if (!this.email) {
            this.setEmail(msg)
            return false;
          }
          return false;
        },
      };
      hC.getOpenHelpArea = function() {
        this.openHelpArea();
      }.bind(this);
      new externalChat(function() {
        setTimeout(function () { hC.hideInput() }, 100)
       }).run(this.chatData, this.$refs.chat);
    }
  },
  mounted() {
    this.loadChat();
    setTimeout(
      function() {
        this.messages.push(hC.addMessage(hC.messages.buttons, 4));
        setTimeout(
          function() {
            hC.checkClick(
              function(input) {
                this.messages.splice(1, 1);
                var data = hC.addMessage(hC.messages.buttons, 4);
                data.msg = input;
                this.messages.push(data);
                this.messages.push(hC.getAnswer(data.msg));
              }.bind(this)
            );
          }.bind(this),
          500
        );
      }.bind(this),
      2000
    );
  },
  computed: {}
};
</script>
<style lang="sass">
#jj_chat_area .msg_area, #jj_chat_area .insert_area, #jj_chat_area .translation_area, #jj_chat_area .writing_area, #jj_chat_area .pause_area
  width: 100% !important
.flix-btn
  padding: 7px
  background-color: white
  display: inline-block
  margin-bottom: 8px
  text-decoration: none
  color: black
  font-weight: bold
  font-size: 12pt
  border: 1px solid black
  border-radius: 5px
</style>
