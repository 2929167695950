var de_DE = function() {

  this.buttons = [
    "Ich habe eine Frage zu Bookingflix allgemein",
    "Ich möchte per Telefon / Fax / E-Mail in Kontakt treten",
    "Ich möchte persönliche Hilfe über den Chat"
  ];
  this.messages = {
    welcome:
      "Hallo, ich bin FLIX aus dem Support-Team.<br><br>Womit kann ich helfen?",
    buttons:
      '<a href="#" class="flix-btn flix-btn-answer flix-btn-default flix-btn-block">' +
      this.buttons[1] +
      "</a><br>" +
     '<a href="#" class="flix-btn flix-btn-answer flix-btn-default flix-btn-block">' + this.buttons[2] + '</a><br>',
    // '<a href="#" class="flix-btn flix-btn-answer flix-btn-default flix-btn-block">' + this.buttons[2] + '</a>',
    writeComment:
      "Schritt 1/2<br><br><b>Wie lautet die Frage / das Anliegen?</b><br><br>Bitte unten eintippen und dann auf senden klicken",
    emailAdress:
      "Schritt 2/2<br><br>WICHTIG! <b>An welche E-Mailadresse soll die Antwort gesendet werden?</b><br><br>Bitte unten eintippen und dann auf senden klicken",
    saveEmail:
      "Wunderbar. Danke. Gerne leite ich die Anfrage an den zuständigen Mitarbeiter.<br><br>Dieser prüft Ihr Anliegen sehr genau. Bitte geben Sie uns entsprechend Zeit für die Bearbeitung. Sie erhalten per E-Mail Rückmeldung zum Anliegen, vermutlich innerhalb der nächsten 24 Stunden.<br><br>Dieser Chat kann jetzt geschlossen werden.",
    helpArea:
      "Für allgemeine Infos zu Terminflix haben wir extra einen Hilfe-Bereich eingerichtet.<br><br>Im Hilfe-Bereich sind alle wichtigen Fragen und Antworten aufgeführt.",
    contact:
      "Unser Support-Team ist erreichbar per Email, Post, Telefon und Fax." +
      "<br>" +
      "<br>" +
      "Tel.: <a href='tel:+49 2302 4007736'>+49 2302 4007736</a>" +
      "<br>" +
      "<br>" +
      "Email: <a href='mailto:support@bookingflix.com'>support@bookingflix.com</a>" +
      "<br>" +
      "<br>" +
      "Postanschrift:" +
      "<br>" +
      "bookingflix.com" +
      "<br>" +
      "Bebelstr. 15" +
      "<br>" +
      "58453 Witten" +
      "<br>" +
      "<br>" +
      "Geschäftszeiten:" +
      "<br>" +
      "Montag bis Freitag, 9.00 Uhr bis 19.00 Uhr" +
      "<br>" +
      "Samstag, 9.00 Uhr bis 16.00 Uhr" +
      "<br>" +
      "Sonntag, 9.00 Uhr bis 16.00 Uhr",
    openHelpArea:
      '<a href="#" class="flix-btn flix-btn-answer flix-btn-default flix-btn-block">Hilfe-Bereich öffnen</a>'
  };

}

export { de_DE };
